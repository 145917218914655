<template>
  <PromoItem
    class="promo-item"
    :color="promo.color"
    :title="promo.title"
    :images="promo.images"
  >
    <Button to="https://disk.yandex.ru/d/yjGUi-GLA4jG6A" type="secondary"
      >Скачать</Button
    >
  </PromoItem>
</template>

<script setup>
import PromoItem from "./PromoItem.vue";
import Button from "./Button.vue";

const promo = {
  color: "black",
  title: `обязательные документы \nдля торговой точки`,
  images: [
    require("@/assets/document/blue/m.webp"),
    require("@/assets/document/blue/d.webp"),
  ],
};
</script>
<style lang="scss" scoped>
.promo-item {
  &:deep(a) {
    width: auto;
    justify-self: start;
  }
}
</style>
