<template>
  <a
    v-if="to"
    :href="to"
    :target="target"
    rel="noopener noreferrer"
    :class="[type]"
  >
    <slot />
  </a>
  <button v-else type="button" :class="[type]">
    <slot />
  </button>
</template>

<script setup>
defineProps({
  to: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    default: "default",
  },
  target: {
    type: String,
    default: "_blank",
  },
});
</script>
<script>
export default {
  name: "ButtonComponent",
};
</script>

<style lang="scss" scoped>
a,
button {
  font-size: var(--sizeButton);
  line-height: var(--lineHeightButton);
  font-family: var(--fontSecondary);
  font-weight: 800;
  text-align: center;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 0 24px;
  border-radius: 12px;
  width: 100%;
  text-transform: uppercase;
  &.default {
    background-color: var(--colorGreen);
    color: var(--colorBlack);
  }
  &.secondary {
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    height: auto;
    color: var(--colorBlack);
    @include md {
      padding: 16px 24px;
      border-radius: 16px;
    }
  }
}
</style>
