<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="apps">
    <div class="apps-head">
      <Card color="primary"><h3>приложения</h3></Card>
      <Card color="grey-dark"></Card>
    </div>
    <div class="apps-body">
      <div class="apps-body__column">
        <Card class="apps-body__card" color="grey-dark">
          <div class="apps-body__section">
            <div class="apps-body__pic">
              <img src="@/assets/apps/LK.svg" alt="" width="124" height="124" />
            </div>
            <div class="apps-body__content">
              <h3>Личный кабинет <span>t2</span></h3>
              <p>Приложение для клиента</p>
            </div>
          </div>
          <div class="apps-body__section">
            <div class="apps-body__link">
              <QRLink
                src="/static/demo/qr-app-1.svg"
                link="https://onelink.to/ts5aby"
              />
            </div>
          </div>
        </Card>
      </div>
      <div class="apps-body__column">
        <Collapse color="grey-dark">
          <template #head
            >Лучший способ управлять услугами и счетом для абонентов
            t2</template
          >
          <template #body>
            <ul>
              <li>подключение к t2 с саморегистрацией;</li>
              <li>подключение eSIM;</li>
              <li>проверка остатков гигабайтов и минут;</li>
              <li>настройка тарифа;</li>
              <li>подключение или отключение дополнительных услуг;</li>
              <li>возможность делиться гигабайтами с абонентами t2;</li>
              <li>возможность продавать и покупать минуты и ГБ;</li>
              <li>
                удобный виджет для отслеживания остатков минут, гигабайтов и
                баланса.
              </li>
            </ul>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head>Управление счетом</template>
          <template #body>
            <ul>
              <li>
                пополнение баланса с помощью бесконтактных платежей или
                банковских карт;
              </li>
              <li>установка платежа с привязанной банковской карты;</li>
              <li>использование обещанного платежа;</li>
              <li>просмотр статистики расходов и заказа детализации;</li>
            </ul>
          </template>
        </Collapse>
        <Collapse color="grey-dark">
          <template #head>Бонусы</template>
          <template #body>
            <ul>
              <li>
                скидки, кешбэки и подарки от наших партнеров по программе
                «Больше»;
              </li>
              <li>
                уникальные предложения и приглашения на интересные мероприятия.
              </li>
            </ul>
          </template>
        </Collapse>
      </div>
    </div>
  </div>
</template>

<script>
import QRLink from "./QRLink.vue";
import Card from "./Card.vue";
import Collapse from "./Collapse.vue";

export default {
  components: {
    QRLink,
    Card,
    Collapse,
  },
};
</script>

<style lang="scss" scoped>
.apps {
  padding-top: var(--widthGapH);
  display: grid;
  gap: var(--widthGapH);
  &-head {
    display: grid;
    gap: var(--widthGapV);
    @include md {
      grid-template-columns: 345px 1fr;
      gap: var(--widthGapV);
    }
    &:deep(:nth-child(1)) {
      height: 80px;
      @include md {
        height: 190px;
      }
    }
    &:deep(:nth-child(2)) {
      height: 114px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      background-image: url(@/assets/apps/promo/m.webp);
      @include md {
        height: 190px;
        background-image: url(@/assets/apps/promo/d.webp);
      }
    }
  }
  &-body {
    display: grid;
    gap: var(--widthGapV);
    align-items: start;
    @include md {
      grid-template-columns: repeat(2, 1fr);
      gap: var(--widthGapV);
      &:deep(.card) {
        border-radius: 18px;
      }
    }
    &__column {
      display: grid;
      gap: 8px;
    }
    &__card {
      display: grid;
      gap: var(--widthGapV);
      @include md {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &__section {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 18px;
      @include md {
        grid-auto-flow: row;
        grid-template-columns: auto;
        align-content: start;
      }
    }
    &__content {
      display: grid;
      align-content: start;
      gap: 8px;
    }
    &__pic {
      img {
        display: block;
      }
    }
    &__desc {
      grid-column: 2 span;
      @include md {
        grid-column: auto;
      }
    }
    &__link {
      grid-column: 2 span;
      @include md {
        grid-column: auto;
      }
    }
  }

  &-list {
    display: grid;
    gap: var(--widthGapH);
    &__head {
    }
    &__body {
      display: grid;
      gap: var(--widthGutter);
    }
    &:deep(.collapse) {
      @include md {
        border-radius: var(--radiusOuter);
      }
    }
  }
  &-swiper {
    position: relative;
    display: grid;
    max-width: 1004px;
    margin: 0 calc(var(--widthPadding) * -1);
    @include md {
      margin: 0;
    }
    &:deep(.swiper) {
      width: 100%;
    }
    &:deep(.swiper-custom-navigation) {
      @include md {
        position: relative;
        filter: invert(1);
        margin: 24px auto 0;
      }
    }
    &:deep(.swiper-custom-pagination) {
      @include md {
        display: none;
      }
    }
  }
}
</style>
