<template>
  <Card
    class="tariff"
    :color="
      item.slug === 'black' || item.slug === 'premium' ? 'black' : 'white'
    "
  >
    <div class="tariff-head">
      <div class="tariff-title">
        {{ item.name }}
        <span
          v-if="item.hit && item.slug !== 'enough'"
          class="tariff-label"
          :style="{ backgroundColor: item.hit.color }"
          :class="{ 'no-icon': item.hit.text === 'Эксклюзив' }"
        >
          {{ item.hit.text }}
        </span>
      </div>
      <div
        v-if="unlimitedWeekend"
        class="tariff-features tariff-features_unlimited"
        :style="{
          backgroundImage: `url(//t2.ru${unlimitedWeekend.image})`,
        }"
      >
        <div class="tariff-features__title">
          {{
            unlimitedWeekend.frontName ? unlimitedWeekend.frontName : "Безлимит"
          }}
        </div>
        <div class="tariff-features__text">
          {{
            unlimitedWeekend.frontNameComment
              ? unlimitedWeekend.frontNameComment
              : "на интернет по выходным"
          }}
        </div>
      </div>
      <div v-else-if="unlimitedIcons.length > 0" class="tariff-features">
        <div class="tariff-features__title" v-html="unlimitedTitle"></div>
        <div class="tariff-features__text" v-html="unlimitedText"></div>
        <ul class="tariff-features__icons">
          <template
            v-for="(item, index) in unlimitedIcons.slice(0, 6)"
            :key="index"
          >
            <li>
              <img
                :src="`${item.icon?.startsWith('/img/') ? '' : '//t2.ru'}${
                  item.icon
                }`"
                alt=""
                width="32"
                height="32"
              />
            </li>
          </template>
          <li
            v-if="unlimitedIcons.length > 6"
            class="tariff-features__icons-more"
          >
            +{{ unlimitedIcons.length - 6 }}
          </li>
        </ul>
      </div>
      <div class="tariff-options">
        <div v-if="item.internetPackage?.value" class="tariff-option">
          <strong v-if="item.internetPackage?.value">
            {{ item.internetPackage.value }}
            {{ item.internetPackage.uom }}
          </strong>
          <span v-if="item.internetPackage?.bonus">
            +{{ item.internetPackage.value }}
            {{ item.internetPackage.uom }} каждый 3-й месяц
          </span>
        </div>
        <div v-if="item.minutes?.value" class="tariff-option">
          <strong v-if="item.minutes?.value">
            {{ item.minutes.value }} минут
          </strong>
          <span v-if="item.minutes?.frontName">
            {{ item.minutes.frontName }} и <b>безлимит</b> на t2 России
          </span>
        </div>
        <div v-else-if="minutesPackage" class="tariff-option">
          <strong v-if="minutesPackage?.value">
            {{ minutesPackage.value }} минут
          </strong>
          <span v-if="minutesPackage?.frontName">
            {{ minutesPackage.frontName }} и <b>безлимит</b> на t2 России
          </span>
        </div>
      </div>
    </div>
    <div class="tariff-foot">
      <div v-if="internetPackage" class="tariff-home">
        <div class="tariff-home__info">
          <span>Домашний интернет:</span>
          <span>от {{ internetPackage.summaryWeight }} Мбит/с</span>
        </div>
        <div class="tariff-home__control">
          <ToggleButton @update="showCost = !showCost" />
        </div>
      </div>
      <Button :to="item.url" class="tariff-button">
        <span v-if="item.price">
          <!-- если zeroSubscriptionFee = true, основная цена 0, зачеркнутая price(abonentFee),
          если showCost(ШПД) = true, основная цена - internetTele2_home.cost(pricesBySpeed), зачеркнутой нету -->
          <template v-if="item.zeroSubscriptionFee">
            <span class="stroke" v-if="!showCost">{{ item.price }} ₽</span>
            {{ showCost ? item.internetTele2_home.cost : 0 }} <span>₽/мес</span>
          </template>
          <template v-else>
            <span class="stroke" v-if="showCost"
              >{{ item.internetTele2_home.cost + item.price }} ₽</span
            >
            {{ item.price }} <span>₽/мес</span>
          </template>
        </span>
        <span v-else>Купить</span>
        <div
          v-if="$store.state.region?.region !== 'siteSTAVROPOL'"
          class="tariff-freeze"
        >
          Цена заморозится до конца 2026 г.
        </div>
      </Button>
    </div>
  </Card>
</template>

<script>
import ToggleButton from "@/components/ToggleButton.vue";
import Button from "./Button.vue";
import Card from "./Card.vue";

export default {
  props: {
    item: Object,
  },
  computed: {
    unlimitedIcons() {
      const { containerParams } = this.item;
      let icons = [];

      // Получаем иконки из containerParams
      if (containerParams) {
        if (containerParams.messengers) {
          icons = icons.concat(
            containerParams.messengers
              .filter((m) => m.selectedIcon)
              .map((m) => ({ icon: m.selectedIcon }))
          );
        }
        if (containerParams.socialNetworks) {
          icons = icons.concat(
            containerParams.socialNetworks
              .filter((s) => s.selectedIcon)
              .map((s) => ({ icon: s.selectedIcon }))
          );
        }
        if (containerParams.videoServices) {
          icons = icons.concat(
            containerParams.videoServices
              .filter((v) => v.selectedIcon)
              .map((v) => ({ icon: v.selectedIcon }))
          );
        }
      }

      // Получаем иконки из parameters с blockAlignment === "unlimitedTraffic"
      const unlimitedParams =
        this.item.parameters
          ?.filter((p) => p.blockAlignment === "unlimitedTraffic")
          .sort((a, b) => b.frontWeight - a.frontWeight) || [];

      icons = icons.concat(
        unlimitedParams.filter((p) => p.image).map((p) => ({ icon: p.image }))
      );

      return icons;
    },
    // TariffsDetailedListT2 не нашел в api тарифа
    // Получить заголовок title и описание text
    // i. Получить заголовок title из {@type == TariffsDetailedListT2}.unlimitedTrafficTitle
    // ii. Получить описание text из {@type == TariffsDetailedListT2}.unlimitedTrafficText
    // iii. Если title == (null || "" ) И/ИЛИ text == (null || "" ), т.е. если хотя бы одно значение не получено,
    // то присвоить значения по умолчанию (хардкод):
    // 1. title = "Безлимит"
    // 2. text = "на общение и развлечения"
    unlimitedTitle() {
      return "Безлимит";
    },
    unlimitedText() {
      return "на общение и развлечения";
    },
    internetPackage() {
      return this.item.parameters?.find((e) => e.key === "пакетИнтернета");
    },
    minutesPackage() {
      return this.item.parameters?.find(
        (e) => e.key === "пакетМинутНаДругиеМобРоссии"
      );
    },
    unlimitedWeekend() {
      return this.item.parameters?.find(
        (e) => e.key === "БезлимитПоВыходнымГлавная"
      );
    },
  },
  components: {
    ToggleButton,
    Card,
    Button,
  },
  data() {
    return {
      showCost: false,
    };
  },
  methods: {
    remakeHREF(str) {
      if (str.includes("Selection")) {
        return str.replace(
          "/bolshe/selection?pageParams=view%3Dlanding",
          "https://msk.t2.ru/bolshe/selection?pageParams=view%3Dlanding"
        );
      }
      return str;
    },
    getSocial(features) {
      // remove if link fix
      const brokenString =
        '<a href="/option/4g-turbo">4G Turbo</a> и <a href="https://selection.t2.ru/">привилегии программы t2 Selection</a>';
      const broken = features.advantages.find(
        (a) => a.frontName === brokenString
      );
      if (broken) {
        broken.frontName =
          '<a href="https://t2.ru/option/4g-turbo" target="_blank">4G Turbo</a> и <a href="https://selection.t2.ru/" target="_blank">привилегии программы t2 Selection</a>';
      }
      // end remove if link fix

      const sortedByPriority = features.advantages
        .concat(features.gamingAdvantages)
        .sort((a, b) => a.priority - b.priority);
      return [
        features.messengers,
        features.socialNetworks,
        ...sortedByPriority,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.tariffs {
  display: grid;
  gap: var(--widthGapH) var(--widthGapV);
  @include md {
    grid-template-columns: repeat(2, 1fr);
  }
}

.tariff {
  display: grid;
  align-content: space-between;
  gap: 16px;
  @include md {
    gap: 24px;
  }
  &.black {
    color: var(--colorWhite);
    .tariff-option {
      color: #bfbfbf;
      &:deep(strong) {
        color: var(--colorWhite);
      }
    }
    .tariff-features__pic {
      li {
        background-color: var(--colorBlack);
      }
    }
  }
  &-head {
  }
  &-title {
    font-family: var(--fontSecondary);
    font-size: var(--sizeButton);
    line-height: var(--lineHeightButton);
    font-weight: 800;
    display: inline-grid;
    grid-auto-flow: column;
    gap: 12px;
    align-items: center;
  }
  &-label {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding: 0 8px;
    border-radius: 10px;
    font-family: var(--fontPrimary);
    font-weight: 400;
  }
  &-options {
    display: grid;
    gap: 32px;
    margin-top: 16px;
    @include md {
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
    }
    &_3 {
      @include md {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  &-option {
    color: #8f8f8f;
    &:deep(strong) {
      display: block;
      font-size: var(--sizeHeading3);
      line-height: var(--lineHeightHeading3);
      font-family: var(--fontSecondary);
      font-weight: 800;
      color: var(--colorBlack);
      margin-bottom: 4px;
      white-space: nowrap;
    }
  }
  &-features {
    display: grid;
    border-radius: 12px;
    background-color: #f2f2f2;
    padding: 12px;
    min-height: 106px;
    align-content: space-between;
    margin-top: 16px;
    &_unlimited {
      background-color: #d9d9d96e;
      background-image: url(~@/assets/icons/enough-home.webp);
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-size: 128px 108px;
      padding-right: 140px;
    }
    &__title {
      font-family: var(--fontSecondary);
      font-weight: 800;
      margin-bottom: 4px;
      font-size: 18px;
      @include md {
        font-size: 20px;
      }
    }
    &__text {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      color: #8f8f8f;
    }
    &__icons {
      @include reset-list;
      display: flex;
      gap: 4px;
      margin-top: 8px;
      li {
        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
          display: block;
        }
      }
      &-more {
        width: 24px;
        height: 24px;
        background-color: #d9d9d9;
        color: var(--colorBlack);
        border-radius: 12px;
        place-content: center;
        text-align: center;
        font-size: 10px;
      }
    }
  }
  &-home {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    @include md {
      margin-bottom: 24px;
    }
    &__info {
      span {
        display: block;
      }
    }
    &__control {
    }
  }
  &-freeze {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-transform: none;
    font-family: var(--fontPrimary);
    margin-top: 2px;
  }
  &-button {
    flex-direction: column;
    &:deep(.stroke) {
      position: relative;
      font-size: 12px;
      line-height: 10px;
      color: #b3b3b3;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        border-top: 2px solid var(--colorPink);
        margin-top: -1px;
        transform: rotate(-10deg);
      }
    }
  }
}
</style>
